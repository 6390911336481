

















































































































import Vue from 'vue';

import { mapGetters, mapActions } from 'vuex';

import EditName from './components/EditName.vue';

import EditEmail from './components/EditEmail.vue';

import OperatorActions from './components/Actions.vue';

import { operatorsGetters, operatorsActions } from './store/names';

export default Vue.extend({
    name: 'OperatorItemPage',

    components: {
        EditName,
        EditEmail,
        OperatorActions,
    },

    data() {
        return {
            operatorId: +this.$route.params.id,
        };
    },

    computed: {
        ...mapGetters({
            operator: operatorsGetters.operatorCurrent,
        }),
    },

    async mounted() {
        await this.pullOperator();
    },

    methods: {
        ...mapActions({
            fetchOperator: operatorsActions.fetchOperator,
        }),

        async pullOperator() {
            await this.fetchOperator({
                id: this.operatorId,
            });
        },
    },
});
