



































































































import Vue, { PropType } from 'vue';

import { mapActions } from 'vuex';

import { required, minLength } from 'vuelidate/lib/validators';

import { operatorsActions } from '@/modules/operators/store/names';

import { Operator } from '../types';

export default Vue.extend({
    name: 'ToggleSuperUser',

    validations: {
        password: { required, minLength: minLength(8) },
    },

    props: {
        operator: {
            type: Object as PropType<Operator>,
            required: true,
        },

        callback: {
            type: Function,
        },
    },

    data() {
        return {
            dialog: false,
            password: '',
            process: false,
            success: false,
            errors: [] as string[],
        };
    },

    computed: {
        isSuperuser():boolean {
            return this.operator.isSuperuser;
        },

        userId(): number {
            return this.operator.id;
        },

        passwordErrors() {
            const errors: string[] = [];
            if (!this.$v.password.$dirty) return errors;
            if (!this.$v.password.minLength) {
                errors.push('Min 8 characters.');
            }
            if (!this.$v.password.required) {
                errors.push('Password is required.');
            }

            return errors;
        },
    },

    methods: {
        ...mapActions({
            promoteOperator: operatorsActions.promoteOperator,
            demoteOperator: operatorsActions.demoteOperator,
        }),

        async submit() {
            this.$v.password.$touch();
            this.errors = [];

            if (!this.$v.password.$invalid) {
                this.process = true;

                const data = {
                    id: this.userId,
                    password: this.password,
                };

                if (!this.isSuperuser) {
                    await this.promoteOperator(data)
                        .then(async () => this.endOfProcessAndSetSuccess())
                        .catch((e) => {
                            this.process = false;
                            this.errors.push(e);
                        });
                } else {
                    await this.demoteOperator(data)
                        .then(async () => this.endOfProcessAndSetSuccess())
                        .catch((e) => {
                            this.process = false;
                            this.errors.push(e);
                        });
                }
            }
        },

        closeDialog() {
            this.errors = [];
            this.dialog = false;
            this.success = false;
            this.password = '';
        },

        async endOfProcessAndSetSuccess() {
            await this.callback();
            this.process = false;
            this.success = true;
        },
    },
});
