




































































import Vue from 'vue';

import { mapActions } from 'vuex';

import { operatorsActions } from '@/modules/operators/store/names';

export default Vue.extend({
    name: 'EditOperatorEmail',

    props: {
        operatorId: {
            type: Number,
            required: true,
        },

        email: {
            type: String,
            required: true,
        },

        callback: {
            type: Function,
        },
    },

    data() {
        return {
            dialog: false,
            isButtonDisabled: false,

            process: {
                state: false,
                prelouder: true,
                icon: false,
            },

            setEmail: '',
        };
    },

    computed: {
        emailModel: {
            get(): string {
                return this.email;
            },
            set(val: string): void {
                this.setEmail = val;
            },
        },
        emailRules() {
            const ruleRequired  = (value: string) => {
                if (value) {
                    this.isButtonDisabled = false;
                    return true;
                }

                this.isButtonDisabled = true;
                return 'E-mail is required';
            };

            const ruleValid = (value: string) => {
                if (/.+@.+\..+/.test(value)) {
                    this.isButtonDisabled = false;
                    return true;
                } 

                this.isButtonDisabled = true;
                return 'E-mail must be valid';
            };

            return [ruleRequired, ruleValid];
        },
    },

    methods: {
        ...mapActions({
            mutateOperator: operatorsActions.editOperator,
        }),

        async submit() {
            this.process.state = true;
            this.process.prelouder = true;
            this.process.icon = false;

            await this.mutateOperator({
                id: this.operatorId,
                email: this.setEmail,
            }).then(async () => {
                this.process.prelouder = false;
                this.process.icon = true;

                await this.callback();

                setTimeout(() => {
                    this.process.state = false;
                    this.dialog = false;
                }, 1000);
            });
        },
    },
});
