




































































import Vue from 'vue';

import { mapActions } from 'vuex';

import { operatorsActions } from '@/modules/operators/store/names';

export default Vue.extend({
    name: 'EditOperatorName',

    props: {
        operatorId: {
            type: Number,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        callback: {
            type: Function,
        },
    },

    data() {
        return {
            editNameDialog: false,
            btnState: true,

            setName: '',

            process: {
                state: false,
                prelouder: true,
                icon: false,
            },
        };
    },

    computed: {
        nameModel: {
            get(): string {
                return this.name;
            },
            set(val: string) {
                this.setName = val;
            },
        },

        updateNameRules() {
            return [
                (value: string) => {
                    if (value) {
                        this.btnState = false;
                        return true;
                    } 

                    this.btnState = true;
                    return 'Name must be valid';
                    
                },
            ];
        },
    },

    methods: {
        ...mapActions({
            mutateOperator: operatorsActions.editOperator,
        }),

        async submit() {
            this.process.state = true;
            this.process.prelouder = true;
            this.process.icon = false;

            await this.mutateOperator({
                id: this.operatorId,
                name: this.setName,
            });

            this.process.prelouder = false;
            this.process.icon = true;

            await this.callback();

            setTimeout(() => {
                this.editNameDialog = false;
                this.process.state = false;
            }, 1000);
        },
    },
});
