

























import Vue, { PropType } from 'vue';

import { mapActions } from 'vuex';

import { operatorsActions } from '@/modules/operators/store/names';

import { Operator } from '../types';

export default Vue.extend({
    name: 'ResetPasswordUser',

    props: {
        operator: {
            type: Object as PropType<Operator>,
            required: true,
        },
    },

    data() {
        return {
            process: false,
            success: false,
        };
    },

    methods: {
        ...mapActions({
            operatorResetPassword: operatorsActions.operatorResetPassword,
        }),

        async submit() {
            this.process = true;

            await this.operatorResetPassword({
                id: this.operator.id,
            }).then(() => {
                this.success = true;
                this.process = false;

                setTimeout(() => {
                    this.success = false;
                }, 1000);
            });
        },
    },
});
