
























import Vue from 'vue';

import ToggleSuperUser from './ToggleSuperUser.vue';
import ToggleBlockUser from './ToggleBlockUser.vue';
import ResetPasswordUser from './ResetPassword.vue';

export default Vue.extend({
    name: 'OperatorActions',

    components: {
        ToggleSuperUser,
        ToggleBlockUser,
        ResetPasswordUser,
    },

    props: {
        operator: {        
            required: true,
        },

        reset: {
            type: Function,
        },
    },

    computed: {
        operatorData() {
            return this.operator;
        },
    },
});
